var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Your order is confirmed!\n  ")]),
      _c("p", [
        _vm._v(
          "\n    Thanks for signing up for Phone Service—your new number is ready.\n  "
        ),
      ]),
      _c("br"),
      _c("h3", [_vm._v("\n    Download the App\n  ")]),
      _c("p", [
        _vm._v("\n    Start making and receiving calls right away.\n  "),
      ]),
      _c("p", [
        _vm._v(
          "\n    Available in the US Apple Store and Google Play Store.\n  "
        ),
      ]),
      _c("virtual-phone-qr-codes", {
        staticClass: "m-2",
        attrs: { "mobile-view": _vm.mobileView },
      }),
      _vm._m(0),
      _c("h3", [_vm._v("\n    Customize your Phone Service\n  ")]),
      _c("p", [
        _vm._v(
          "\n    After downloading the app—or by visiting the Phones tab in your online account—you can:\n  "
        ),
      ]),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [_vm._v("Note:")]),
      _vm._v(
        " Your mobile username defaults to your account’s primary email, and the password is the same one you use to log into the portal.\n  "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("\n      Set up call forwarding (mobile or web)\n    "),
      ]),
      _c("li", [
        _vm._v("\n      Record your voicemail greeting (mobile or web)\n    "),
      ]),
      _c("li", [
        _vm._v(
          "\n      Set your Caller ID and update your mobile username (web only)\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }