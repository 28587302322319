<template>
  <div>
    <h3>
      Your order is confirmed!
    </h3>

    <p>
      Thanks for signing up for Phone Service—your new number is ready.
    </p>

    <br>

    <h3>
      Download the App
    </h3>

    <p>
      Start making and receiving calls right away.
    </p>

    <p>
      Available in the US Apple Store and Google Play Store.
    </p>

    <virtual-phone-qr-codes :mobile-view="mobileView" class="m-2" />
    
    <p>
      <b>Note:</b> Your mobile username defaults to your account’s primary email, and the password is the same one you use to log into the portal.
    </p>

    <h3>
      Customize your Phone Service
    </h3>
    
    <p>
      After downloading the app—or by visiting the Phones tab in your online account—you can:
    </p>

    <ul>
      <li>
        Set up call forwarding (mobile or web)
      </li>
      <li>
        Record your voicemail greeting (mobile or web)
      </li>
      <li>
        Set your Caller ID and update your mobile username (web only)
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VirtualPhoneQrCodes from '@/components/VirtualPhones/VirtualPhoneQrCodes.vue'

export default {
  name: 'PhoneServiceOrderConfirmation',
  components: {
    VirtualPhoneQrCodes,
  },

  computed: {
    ...mapGetters('website', ['website']),
    mobileView() {
      return window.innerWidth <= 768
    },
  },
}
</script>

